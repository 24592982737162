import React from 'react'
import img404 from '../../../assets/images/image404.png'
import Button from '../../../shared/components/atoms/button/button'
import './404-content.scss'

const NotFoundContent = () => {

  return (
    <div className="f-section-404">
      <h3 className="f-title">¡Ooops! No hay nada en este lugar</h3>
      <p className="f-description">Es posible que la página que buscas no exista o se haya movido, revisa e intenta nuevamente.</p>

      <div className="f-containter-404-image">
        <img src={img404} alt="404 image" />
        <Button className="f-primary-green f-button-404" title="Volver al home" href={""} />
      </div>
    </div>
  )
}

export default NotFoundContent
