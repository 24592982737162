import React from 'react'
import Layout from "../shared/components/organisms/layout/layout";
import SEO from "../shared/components/atoms/seo/seo"
import NotFoundContent from '../components/organisms/404-content/404-content'
import '../shared/styles/index.scss';

const Page404 = () => {
  return (
    <Layout shopButton={false}>
      <NotFoundContent />
    </Layout>
  )
}

export default Page404
