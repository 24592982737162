import React from "react"
import homeLink from "../../../utils/siteUrl"

import "./button.scss"

const Button = props => {
  const {
    className,
    title,
    onClick,
    typeButton,
    href = "",
    disabled,
    ...params
  } = props
  const newTab = href.includes("https") || href.includes("http")
  const newHref = newTab ? href : homeLink + href
  // console.log("href", href)

  if (typeButton === "button") {
    return (
      <button
        className={"f-btn " + className}
        onClick={onClick}
        disabled={disabled}
        {...params}
      >
        {title}
      </button>
    )
  } else {
    return (
      <a
        href={newHref}
        target={newTab ? "_blank" : "_self"}
        className={(typeButton === "link" ? "f-link " : "f-btn ") + className}
        onClick={e => {
          if (onClick) {
            e.preventDefault()
            onClick()
          }
        }}
        disabled={disabled}
        {...params}
      >
        {title}
      </a>
    )
  }
}

export default Button
